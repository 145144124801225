var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tempLogin"},[_c('div',{staticClass:"loginBox",style:(_vm.style)},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"title"},[_vm._v("欢迎使用蜜葵管理后台")]),_c('p',{staticClass:"subTitle"}),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [{ required: true, message: '请输入账号!' }],
            } ]),expression:"[\n            'username',\n            {\n              rules: [{ required: true, message: '请输入账号!' }],\n            },\n          ]"}],attrs:{"placeholder":"请输入账号"}},[_c('img',{staticClass:"phone_icon",staticStyle:{"width":"14px"},attrs:{"slot":"prefix","src":require("../assets/icon_phone.png"),"alt":""},slot:"prefix"})])],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [{ required: true, message: '请输入密码!' }],
            } ]),expression:"[\n            'password',\n            {\n              rules: [{ required: true, message: '请输入密码!' }],\n            },\n          ]"}],attrs:{"type":"password","placeholder":"请输入密码"}},[_c('img',{staticStyle:{"width":"18px"},attrs:{"slot":"prefix","src":require("../assets/icon_psw.png"),"alt":""},slot:"prefix"})])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 登录 ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }