<template>
  <div class="tempLogin">
    <div class="loginBox" :style="style">
      <a-form
        id="components-form-demo-normal-login"
        :form="form"
        class="login-form"
        @submit="handleSubmit"
      >
        <div class="title">欢迎使用蜜葵管理后台</div>
        <p class="subTitle"></p>
        <a-form-item>
          <a-input
            v-decorator="[
              'username',
              {
                rules: [{ required: true, message: '请输入账号!' }],
              },
            ]"
            placeholder="请输入账号"
          >
            <img
              src="../assets/icon_phone.png"
              alt=""
              slot="prefix"
              style="width: 14px"
              class="phone_icon"
            />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: '请输入密码!' }],
              },
            ]"
            type="password"
            placeholder="请输入密码"
          >
            <img
              src="../assets/icon_psw.png"
              alt=""
              slot="prefix"
              style="width: 18px"
            />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit" class="login-form-button">
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spinning: true,
      form: this.$form.createForm(this, { name: "normal_login" }),
      style: "background-size:cover",
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  activated() {
    this.form.setFieldsValue({
      userName: "",
      password: "",
    });
  },
  watch: {
    theme:{
      handler(val) {
        if(val){
          this.windowResize();
        }
      },
      immediate: true,
    }
  },
  mounted() {
    window.addEventListener("resize", () => this.windowResize(), false);
  },
  methods: {
    windowResize() {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      var clientW =
        document.documentElement.clientWidth || document.body.clientWidth;
        console.log(clientW)
      
       this.style = "background-size:cover";
      
    },
    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.form.validateFields((err, values) => {
        if (!err) {
          that.$message.loading({ content: "加载中..", key: "load",duration: 0  });
          var data = { username: values.username, password: values.password };
          that.axios.post("/api/admin/auth/login", data).then((res) => {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0.00001,
            });
            if (that.$code(res)) {
              that.axios.defaults.headers.post["Authorization"] =
                res.data.resultObject.access_token;
              window.localStorage.setItem(
                "Authorization",
                res.data.resultObject.access_token
              );
              that.$store.commit(
                "changeToken",
                res.data.resultObject.access_token
              );
              that.$message.loading({ content: "加载中..", key: "load",duration: 0  });
              that.axios.get("/api/admin/auth/me").then(function (mes) {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(mes)) {
                  // window.localStorage.setItem(
                  //   "nickName",
                  //   mes.data.resultObject.nickname
                  // );
                  // window.localStorage.setItem(
                  //   "id",
                  //   mes.data.resultObject.id
                  // );
                  that.$nextTick(() => {
                    that.$router.push(
                      "/goods"
                    );
                  });
                }
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.tempLogin {
  background: rgb(245, 245, 245);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginBox {
  width: 520px;
  height: 496px;
}
.ant-form {
  width: 520px;
  height: 496px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
}
.title {
  font-size: 36px;
  line-height: 50px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 6px;
  letter-spacing: 1px;
}
.subTitle {
  font-size: 12px;
  color: #000000;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 43px;
}
.ant-form-item {
  width: 400px;
  margin-bottom: 0;
  height: 80px;
}
.ant-form-item:last-child {
  height: 50px;
}
/deep/.ant-input {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #dbdbdb;
}
/deep/.ant-btn-primary {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  font-size: 16px;
  color: #fff;
  background:rgba(253,105,150);
  border-color:rgba(253,105,150)
}
/deep/.ant-btn-primary:hover {
  opacity: 0.6;
}
/deep/.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 46px;
}
/deep/.ant-input-affix-wrapper .ant-input-prefix {
  left: 20px;
}
@media screen and (max-width: 1096px) {
  .loginBox {
    width: 100%;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-form {
    margin: 0;
    background: #fff;
    border-radius: 12px;
  }
}
@media screen and (max-width: 768px) {
  .title {
    font-size: 28px;
  }
  .ant-form {
    width: 320px;
    height: 400px;
  }
  .ant-form-item {
    width: 300px;
  }
}
</style>